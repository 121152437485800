import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const dashboardService = {
    findRecallsCard(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
            { query: 'eventCategory', val: payload.eventCategory },
        ]
        return http.get(`${API_ROOT}/supplier/dashboard${param(arr)}`).then(resp => {
            return resp
        })
    },
    downloadRecallsCard(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
        ]
        return http.get(`${API_ROOT}/supplier/dashboard/DownloadExcel/${param(arr)}`).then(resp => {
            return resp
        })
    },
}

export default dashboardService
